import React from "react"
import styled from "styled-components"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"
import Reset from "../components/Auth/Reset"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const ResetPage = ({ location }) => {
  return (
    <Container>
      <Layout blank location={location}>
        <SEO
          title="Reset Password"
          location={location}
          post_id={post_id}
          seo={seo}
        />
        <Reset />
      </Layout>
    </Container>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div``

// ======================
// 	⚙️⚙️ CONFIG ⚙️⚙️
// ======================
const post_id = -1 //-1 means no post id
const seo = {
  yoastTitle: "Reset Password",
  yoastDescription:
    "Reset your password for your account at The Mattress Warehouse. Follow the simple steps to regain access to your account.",
  yoastMeta: `[
    {"property":"og:title","content":"Reset Password"},
    {"property":"og:site_name","content":"The Mattress Warehouse"}
  ]`,
  yoastSchema: "",
  woocommerceSchema: "",
}

export default ResetPage
