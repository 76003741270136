import React, { useState, useEffect } from "react"
import styled from "styled-components"
import SplitLayout from "./SplitLayout"
import Error from "./Error"
import BlurLoader from "../../zzz/organisms/blurLoader"
import Logo from "../../zzz/atoms/logo"
import ShowOnButton from "./ShowOnButton"
import { getQueryVariable } from "../../lib/util"
import { graphql, useStaticQuery } from "gatsby"
import { requestPassword, submitPassword } from "../../lib/woocommApi"
import PasswordSubmit from "./Reset/PasswordSubmit"
import EmailSubmit from "./Reset/EmailSubmit"
import { LinkWrapper as Link } from "../../utils/linkWrapper"
import { getImage } from "../../lib/util"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Reset = ({ location }) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [error, setError] = useState("")
  const [tk, setTk] = useState(false)
  const { file } = useStaticQuery(IMAGE)

  // Get token from URL if exists
  useEffect(() => {
    getQueryVariable("tok", setTk)
  })

  const submitHandler = ({ email, password }) => {
    setError("")
    setMessage("")
    setLoading(true)
    if (email) {
      requestPassword(email)
        .then((res) => {
          console.log("req then: ", res)
          setLoading(false)
          if (res && res.message) {
            setMessage(res.message)
          }
        })
        .catch((error) => {
          console.log("req catch: ", error)
          if (error.message) setError(error.message)
          else
            setError("Your request has failed. Please contact us for support.")
          setLoading(false)
        })
    } else if (tk) {
      submitPassword(password, tk)
        .then((res) => {
          setLoading(false)
          if (res && res.message) {
            setMessage(res.message)
          }
        })
        .catch((error) => {
          if (error.message) setError(error.message)
          else
            setError("Your request has failed. Please contact us for support.")
          setLoading(false)
        })
    }
  }

  return (
    <SplitLayout image={getImage(file)} backgroundPosition={"right"}>
      <Skinny>
        <BlurLoader loading={loading} message="Requesting" shade="light">
          <Link to="/">
            <Logo />
          </Link>
          <Title>Reset your Password</Title>
          {!tk && (
            <EmailSubmit
              ExtraStyled={Extra}
              message={message}
              error={error}
              ButtonStyled={Button}
              submitHandler={submitHandler}
            />
          )}
          {tk && (
            <PasswordSubmit
              ExtraStyled={Extra}
              message={message}
              error={error}
              ButtonStyled={Button}
              submitHandler={submitHandler}
            />
          )}
          <ShowOnButton />
        </BlurLoader>
      </Skinny>
    </SplitLayout>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const Extra = styled.div`
  color: white;
  text-align: center;
  font-weight: lighter;
  &.message {
    padding-bottom: 15px;
    font-size: 18px;
    color: #63ceff;
  }
  &.error {
    padding-bottom: 15px;
    font-size: 18px;
    color: #fd3237;
  }
  a {
    color: white;
    text-decoration: underline;
  }
`

const Button = styled.button`
  background: #fd3237;
  border-radius: 3px;
  font-size: 20px;
  color: white;
  border: none;
  display: flex;
  padding: 0;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  margin: 30px 0;
  div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    width: 100%;
  }
  span.icon {
    background: #ed1c24;
    padding: 7px;
  }
`
const Title = styled.h3`
  font-size: 39px;
  color: white;
  text-align: center;
  margin: 20px 0;
`
const Skinny = styled.div`
  max-width: 350px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================

const validate = ({ email, password }) => {
  const errors = {}
  const addIf = (pred, prop, message) => pred && (errors[prop] = message)

  addIf(!email, "email", "Email is required")
  addIf(!/\S+@\S+\.\S+/.test(email), "email", "Email is invalid")

  return errors
}

const IMAGE = graphql`
  {
    file(relativePath: { eq: "lady-in-bed.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`

export default Reset
