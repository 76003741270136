import React, { useState } from "react"
import { Formik, Form } from "formik"
import Input from "../Input"
import SvgIcon from "../../../zzz/atoms/icons/svg-icon"
import Lock from "../../../zzz/atoms/icons/files/lock.jsx"
import { LinkWrapper as Link } from "../../../utils/linkWrapper"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const PasswordSubmit = ({
  ExtraStyled,
  ButtonStyled,
  submitHandler,
  message,
  error,
}) => {
  const [hidePassword, setHidePassword] = useState(true)

  return (
    <>
      <ExtraStyled>Enter your new password below.</ExtraStyled>
      <Formik
        initialValues={{ password: "" }}
        validate={validate}
        onSubmit={submitHandler}
      >
        {({ isSubmitting, errors, touched }) => (
          <>
            <Form>
              <Input
                label="Password:"
                type={hidePassword ? "password" : "text"}
                hidePassword={hidePassword}
                name="password"
                autoComplete="new-password"
                errors={errors}
                touched={touched}
                toggle={() => {
                  setHidePassword((prevState) => !prevState)
                }}
              />
              <Input
                label="Confirm Password:"
                type={hidePassword ? "password" : "text"}
                hidePassword={hidePassword}
                name="passwordConfirm"
                autoComplete="new-password"
                toggle={() => {
                  setHidePassword((prevState) => !prevState)
                }}
                errors={errors}
                touched={touched}
              />

              <ButtonStyled type="submit">
                <div>RESET</div>
                <SvgIcon
                  SvgComponent={Lock}
                  size="lg"
                  hue="white"
                  shade="000"
                />
              </ButtonStyled>
            </Form>
          </>
        )}
      </Formik>
      {message && <ExtraStyled className="message">{message}</ExtraStyled>}
      {error && <ExtraStyled className="error">{error}</ExtraStyled>}
      <ExtraStyled>
        Back to <Link to="/login/">Login</Link>
      </ExtraStyled>
    </>
  )
}

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================

const validate = ({ password, passwordConfirm }) => {
  const errors = {}
  const addIf = (pred, prop, message) => pred && (errors[prop] = message)

  addIf(!password, "password", "Password is required")
  addIf(password !== passwordConfirm, "password", "Passwords do not match")
  addIf(
    password.length < 6,
    "password",
    "Password should be at least 6 characters"
  )

  return errors
}

export default PasswordSubmit
