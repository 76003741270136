import React from "react"
import { Formik, Form } from "formik"
import Input from "../Input"
import SvgIcon from "../../../zzz/atoms/icons/svg-icon"
import ArrowRight from "../../../zzz/atoms/icons/files/arrow-right.jsx"
import { LinkWrapper as Link } from "../../../utils/linkWrapper"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const EmailSubmit = ({
  ExtraStyled,
  ButtonStyled,
  submitHandler,
  message,
  error,
}) => {
  return (
    <>
      <ExtraStyled>
        Reset your password by submitting your email address below. We will send
        you an email with a link to choose a new password.
      </ExtraStyled>
      <Formik
        initialValues={{ email: "" }}
        validate={validate}
        onSubmit={submitHandler}
      >
        {({ isSubmitting, errors, touched }) => (
          <>
            <Form>
              <Input
                label="Email"
                type="email"
                name="email"
                autoComplete="email"
                placeholder="jane@doe.com"
                errors={errors}
                touched={touched}
              />

              <ButtonStyled type="submit">
                <div>RESET</div>
                <SvgIcon
                  SvgComponent={ArrowRight}
                  size="lg"
                  hue="white"
                  shade="000"
                />
              </ButtonStyled>
            </Form>
          </>
        )}
      </Formik>
      {message && <ExtraStyled className="message">{message}</ExtraStyled>}
      {error && <ExtraStyled className="error">{error}</ExtraStyled>}
      <ExtraStyled>
        Back to <Link to="/login/">Login</Link>
      </ExtraStyled>
    </>
  )
}

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================

const validate = ({ email }) => {
  const errors = {}
  const addIf = (pred, prop, message) => pred && (errors[prop] = message)

  addIf(!email, "email", "Email is required")
  addIf(!/\S+@\S+\.\S+/.test(email), "email", "Email is invalid")

  return errors
}

export default EmailSubmit
